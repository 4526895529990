/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('./fonts/roboto-v30-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/roboto-v30-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local(''),
       url('./fonts/roboto-v30-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/roboto-v30-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('./fonts/roboto-v30-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/roboto-v30-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-900 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: local(''),
       url('./fonts/roboto-v30-latin-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/roboto-v30-latin-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: Roboto;
}

a.anchor {
  display: block;
  position: relative;
  top: -80px;
  visibility: hidden;
}

h1 {
  color: #fff;
  font-size: 4rem;
  margin-top: 10px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 700px) {
  h1 {
    font-size: 3rem;
  }
}
@media only screen and (max-width: 500px) {
  h1 {
    font-size: 2.7rem;
  }
}

h2 {
  color: #3383cb;
  font-size: 2.1rem;
  margin-top: 10px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 700px) {
  h2 {
    font-size: 1.7rem;
  }
}
@media only screen and (max-width: 500px) {
  h2 {
    font-size: 1.8rem;
  }
}

.blackback {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  background: rgba(0, 0, 0, 0.2);
}
.whiteback {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  background: rgba(255, 255, 255, 0.2);
}

.photoback {
  background-size: cover;
  background-position: center;
  overflow: hidden;
  z-index: -2;
  position: relative;
}
