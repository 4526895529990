@mixin teaser {
  display: flex;
  align-items: center;
}

.teaser-left {
  @include teaser;
  justify-content: flex-start;
}
.teaser-right {
  @include teaser;
  justify-content: flex-end;
}

@mixin headline {
  display: flex;
  z-index: 1;
}

.headline-left {
  @include headline;
  margin-left: 5%;
}

.headline-right {
  @include headline;
  text-align: right;
  margin-right: 5%;
}

.contentDiv {
  padding: 15px;
  text-align: center;
}

.contentBS {
  text-align: left;
  // font-size: 0.9rem;
}

.shoutEntwicklungBS {
  font-size: 1.3rem;
  font-weight: 900;
  color: #fff;
}
